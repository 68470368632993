<div class="section-routes">
  <soe-section-route-item
    [hasStartArrow]="false"
  >
    <i class="fa fa-home" aria-hidden="true"></i>
  </soe-section-route-item>
  <ng-container *ngFor="let section of routes">
    <soe-section-route-item
      [hasStartArrow]="true"
      (click)="goTo(section.path)"
      class="display-pointer"
    >
      <p>{{ section.route }}</p>
    </soe-section-route-item>
  </ng-container>
</div>
