import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class AnalyticsService {

  constructor(private readonly angulartics: Angulartics2) { }

  onChangeSection(sectionName: string): void {
    this.registerEvent({
      event: 'virtualpageview',
      pagename: sectionName,
      pagepath: `/soe/${sectionName}`
    });
  }

  private registerEvent(event: any): void {
    this.angulartics.eventTrack.next({ properties: { gtmCustom: event } });
  }
}
