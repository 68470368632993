import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AnalyticsService } from './services/analytics/analytics.service';

@NgModule({
  declarations: [],
  imports: [
    SharedModule
  ],
  providers: [
    AnalyticsService
  ]
})
export class CoreModule { }
