import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'soe-section-route',
  templateUrl: './soe-section-route.component.html',
  styleUrls: ['./soe-section-route.component.scss']
})
export class SoeSectionRouteComponent {

  @Input() routes: Array<{route: string, path: string}>;

  constructor(private readonly router: Router) {}

  goTo(path: string): void {
    this.router.navigateByUrl(path);
  }

}
