import { Component } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'sil-angular-web';

  constructor(private readonly angularAnalytics: Angulartics2GoogleTagManager) {
    this.angularAnalytics.startTracking();
  }
}
