import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'soe-section-item',
  templateUrl: './soe-section-item.component.html',
  styleUrls: ['./soe-section-item.component.scss']
})
export class SoeSectionItemComponent {

  @Input() name: string;
  @Input() sectionImg: string;
  @Input() path: string;

  constructor(private readonly router: Router) { }

  goToPath(): void {
    this.router.navigateByUrl(this.path);
  }

}
