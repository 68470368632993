<div id="{{ id }}" class="soe-section-route">
  <div
    class="section-route-start"
    *ngIf="hasStartArrow && canShowStartArrow()"
  ></div>
  <div class="section-route">
    <ng-content></ng-content>
  </div>
  <div class="section-route-end"></div>
</div>
