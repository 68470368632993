import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GALLERY_CONFIG, GalleryModule } from 'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxModule } from 'ng-gallery/lightbox';
import { PlyrModule } from 'ngx-plyr';

import { SoeScrollUpButtonComponent } from './components/soe-scroll-up-button/soe-scroll-up-button.component';
import { SoeSectionItemComponent } from './components/soe-section-item/soe-section-item.component';
import { SoeSectionRouteComponent } from './components/soe-section-route/soe-section-route.component';
import { SoeTitleComponent } from './components/soe-title/soe-title.component';
import { SoeSectionRouteItemComponent } from './components/soe-section-route-item/soe-section-route-item.component';

@NgModule({
  declarations: [
    SoeTitleComponent,
    SoeSectionItemComponent,
    SoeSectionRouteComponent,
    SoeScrollUpButtonComponent,
    SoeSectionRouteItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GalleryModule,
    LightboxModule,
    PlyrModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GalleryModule,
    LightboxModule,
    PlyrModule,
    SoeTitleComponent,
    SoeSectionItemComponent,
    SoeSectionRouteComponent,
    SoeScrollUpButtonComponent
  ],
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: false,
        counter: false,
        imageSize: 'contain',
        thumbWidth: 80
      }
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false
      }
    }
  ]
})
export class SharedModule { }
