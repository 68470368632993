<div class="soe-section-item" (click)="goToPath()">
  <div class="section">
    <img [src]="sectionImg" class="section-img">
  </div>
  <div class="d-flex justify-content-center align-items-center section-title">
    <h3>
      {{ name }}
    </h3>
  </div>
</div>
