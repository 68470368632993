import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'soe-scroll-up-button',
  templateUrl: './soe-scroll-up-button.component.html',
  styleUrls: ['./soe-scroll-up-button.component.scss']
})
export class SoeScrollUpButtonComponent implements OnInit {

  private pageYoffset = 0;

  @HostListener('window:scroll', ['$event']) onScroll() {
    this.pageYoffset = window.pageYOffset - (window.innerWidth/3);
  }

  constructor(
    private readonly scroll: ViewportScroller,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.changeRouteDetect();
  }

  scrollToTop(): void {
    this.scroll.scrollToPosition([0, 0]);
  }

  showScrollButton(): boolean {
    return this.pageYoffset > 0;
  }

  private changeRouteDetect(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.scrollToTop();
      }
    });
  }

}
