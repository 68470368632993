import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'soe-section-route-item',
  templateUrl: './soe-section-route-item.component.html',
  styleUrls: ['./soe-section-route-item.component.scss']
})
export class SoeSectionRouteItemComponent {

  @Input() hasStartArrow: boolean = true;
  @ViewChild('soeSectionItem') sectionItem: ElementRef;

  id: string;

  ngOnInit(): void {
    this.id = `${Math.random() + new Date().getTime()}`;
  }

  canShowStartArrow(): boolean {
    const leftOffset = document.getElementById(this.id).offsetLeft;
    return leftOffset > 16;
  }

}
